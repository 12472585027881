



















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import userManager from "@/services/userManager";
import orderPickUpStatuses from "@/constants/orderPickUpStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";
import ProductOrCombo from "./partials/ProductOrCombo.vue";
import orderCustomerRefusedStatuses from "@/constants/orderCustomerRefusedStatuses";

export default Vue.extend({
  components: {
    ProductOrCombo,
  },
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": false,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          customPerPageEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: {
              text: "Sản phẩm",
            },
            giftedProduct: {
              text: "Quà tặng",
              options: {
                subProp: "giftedProduct.sku",
                label: true,
                sortBy: "giftedProduct.name",
              },
            },
            singlePrice: {
              text: "Đơn giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            count: {
              text: "Số lượng",
              sortable: true,
            },
            totalPrice: {
              text: "Tổng giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            customerName: {
              text: "Tên",
              sortable: true,
            },
            customerEmail: {
              text: "Email",
              sortable: true,
            },
            customerPhone: {
              text: "SĐT",
              sortable: true,
            },
            customerMessage: {
              text: "Lời nhắn",
              sortable: true,
            },
            customerState: !userManager.checkRole(["content", "design"], true) && {
              text: "State",
            },
            customerCity: !userManager.checkRole(["content", "design"], true) && {
              text: "City",
            },
            customerDistrict: !userManager.checkRole(["content", "design"], true) && {
              text: "District",
            },
            customerWard: !userManager.checkRole(["content", "design"], true) && {
              text: "Ward",
            },
            customerAddress: !userManager.checkRole(["content", "design"], true) && {
              text: "Address",
            },
            customerPostalCode: !userManager.checkRole(["content", "design"], true) && {
              text: "Postal Code",
            },
            shippingCompany: {
              text: "ĐVVC",
              options: {
                subProp: "shippingCompany.name",
                sortBy: "shippingCompanyId",
              },
            },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              customerName: {
                attrs: {
                  label: "Tên KH",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: {
                attrs: {
                  label: "SĐT",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: {
                attrs: {
                  label: "Lời nhắn",
                },
                rules: {
                  equal_to: {},
                },
              },
              pickUpStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái pick up",
                  items: makeSelectOptions(orderPickUpStatuses),
                },
                rules: {
                  in: {},
                },
              },
              shippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              customerRefusedStatus: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái hủy đơn",
                  items: [
                    {
                      text: "Trống",
                      value: "",
                    },
                  ].concat(makeSelectOptions(orderCustomerRefusedStatuses)),
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                },
              },
            },
          },
          multiSelectActionButtons: {
            setKt: userManager.checkRole(["kt", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Chuyển Telesale ODZ",
                icon: "mdi-phone",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Xác nhận chuyển lại cho telesale với trạng thái địa chỉ ODZ?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "updateMany",
                            {},
                            {
                              ids,
                              data: {
                                status: "kt",
                                locationStatus: "odz",
                              },
                              updateRequest: {
                                scenario: "PickUpPrepareSetLocationStatusKt",
                              },
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
            setCpu: userManager.checkRole(["kt", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Sẵn sàng xuất đi",
                icon: "mdi-check",
              },
              target: {
                confirm: {
                  content: {
                    content: {
                      html: "Sẵn sàng xuất đi?",
                    },
                  },
                  on: {
                    async xYes({ self: dialog }) {
                      const btn = dialog.context();
                      const dataTable = btn.context();
                      const ids = dataTable.model.value.map((item) => item._id);
                      const result = await dataTable.execute(
                        async () => {
                          return await coreApiClient.call(
                            "orders",
                            "updateMany",
                            {},
                            {
                              ids,
                              data: {
                                status: "cpu",
                              },
                              updateRequest: {
                                scenario: "PickUpPrepareSetStatusCpu",
                              },
                            }
                          );
                        },
                        undefined,
                        {
                          disableLoading: true,
                          willRefresh: true,
                        }
                      );
                      if (result) {
                        dialog.hide();
                      }
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "status",
              operator: "equal_to",
              value: "cbpu",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
