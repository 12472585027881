export default function makeSelectOptions(m: any, filter?: any) {
    const options = []
    for (const k in m) {
        const v = m[k]
        if (filter && !filter(k, v)) {
            continue
        }
        options.push({
            value: k,
            text: v,
        })
    }
    return options
}
