























































import Vue from "vue";
export default Vue.extend({
  props: ["item"],
  data() {
    return {
      expanded: this.item.isExternalOrder && this.item.invalidSkus?.length,
    };
  },
  computed: {
    valid() {
      return !this.item.productInvalid && this.item.productItems && this.item.productItems.length;
    },
    orderRequestInfo() {
      const arr = [];
      for (const key in this.item.orderRequest) {
        arr.push({
          key,
          value: this.item.orderRequest[key],
        });
      }
      return arr;
    },
  },
});
